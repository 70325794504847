export const themes = {
  // Dark Themes

  default: {
    name: 'Deep Space',
    primary: '#7B4DFF',
    background: '#0A0E21',
    text: '#E0E0E0',
    description: 'Explore the cosmos',
    category: 'dark',
    accent: '#4A0E4E',
    secondary: '#1F2041',
    highlight: '#C04CFD',
    button: '#7e30cf',
    link: '#A06CD5',
    border: '#2D3250',
    hover: '#8F7DC6'
  },
  Netflix: {
    name: 'Netflix',
    primary: '#E50914',
    background: '#141414',
    text: '#FFFFFF',
    description: 'Modern streaming theme',
    category: 'dark',
    accent: '#B20710',
    secondary: '#222222',
    highlight: '#F40612',
    button: '#E50914',
    link: '#FF0A16',
    border: '#333333',
    hover: '#C11119'
  },
    // Additional Themes
  chemical: {
    name: 'Chemical',
    primary: '#1CE783',
    background: '#0B0C0F',
    text: '#FFFFFF',
    description: 'Toxic and glowing',
    category: 'dark',
    accent: '#00FF00',
    secondary: '#1A1C20',
    highlight: '#39FF14',
    button: '#0F9B58',
    link: '#7FFF00',
    border: '#2F4F4F',
    hover: '#32CD32'
  },

  azureWaters: {
    name: 'Azure Waters',
    primary: '#00A8E1',
    background: '#0F171E',
    text: '#FFFFFF',
    description: 'Deep ocean vibes',
    category: 'dark',
    accent: '#00CED1',
    secondary: '#1B2838',
    highlight: '#40E0D0',
    button: '#008B8B',
    link: '#20B2AA',
    border: '#2C4356',
    hover: '#48D1CC'
  },

  sunsetOrange: {
    name: 'Sunset Orange',
    primary: '#F47521',
    background: '#23252B',
    text: '#FFFFFF',
    description: 'Warm evening glow',
    category: 'dark',
    accent: '#FF6347',
    secondary: '#3A3C42',
    highlight: '#FFA07A',
    button: '#FF4500',
    link: '#FF7F50',
    border: '#5A5C62',
    hover: '#FF8C00'
  },
  emeraldForest: {
    name: 'Emerald Forest',
    primary: '#1DB954',
    background: '#191414',
    text: '#FFFFFF',
    description: 'Lush and verdant',
    category: 'dark',
    accent: '#2ECC71',
    secondary: '#282828',
    highlight: '#00FA9A',
    button: '#16A34A',
    link: '#3CB371',
    border: '#3C3C3C',
    hover: '#4CAF50'
  },
  midnightGold: {
    name: 'Midnight Gold',
    primary: '#FBB400',
    background: '#000030',
    text: '#FFFFFF',
    description: 'Luxurious dark blue and gold',
    category: 'dark',
    accent: '#FFD700',
    secondary: '#000050',
    highlight: '#FFDF00',
    button: '#D4AF37',
    link: '#DAA520',
    border: '#000080',
    hover: '#F0E68C'
  },
  retroWave: {
    name: 'Retro Wave',
    primary: '#FF1493',
    background: '#1E0B39',
    text: '#00FFFF',
    description: 'Synthwave aesthetic',
    category: 'dark',
    accent: '#FF00FF',
    secondary: '#2E1A47',
    highlight: '#FF69B4',
    button: '#8B008B',
    link: '#FF6FFF',
    border: '#4B0082',
    hover: '#DA70D6'
  },
  nordAurora: {
    name: 'Nord Aurora',
    primary: '#88C0D0',
    background: '#2E3440',
    text: '#D8DEE9',
    description: 'Arctic-inspired palette',
    category: 'dark',
    accent: '#81A1C1',
    secondary: '#3B4252',
    highlight: '#5E81AC',
    button: '#5E81AC',
    link: '#8FBCBB',
    border: '#4C566A',
    hover: '#B48EAD'
  },
  monokai: {
    name: 'Monokai',
    primary: '#F92672',
    background: '#272822',
    text: '#F8F8F2',
    description: 'Popular code editor theme',
    category: 'dark',
    accent: '#A6E22E',
    secondary: '#3E3D32',
    highlight: '#66D9EF',
    button: '#FD971F',
    link: '#AE81FF',
    border: '#75715E',
    hover: '#E6DB74'
  },
  gruvbox: {
    name: 'Gruvbox',
    primary: '#FE8019',
    background: '#282828',
    text: '#EBDBB2',
    description: 'Retro groove color scheme',
    category: 'dark',
    accent: '#B8BB26',
    secondary: '#3C3836',
    highlight: '#83A598',
    button: '#D79921',
    link: '#8EC07C',
    border: '#504945',
    hover: '#FB4934'
  },
 
  oceanBreeze: {
    name: 'Ocean Breeze',
    primary: '#48CAE4',
    background: '#E0FBFC',
    text: '#023047',
    description: 'Cool coastal colors',
    category: 'light',
    accent: '#90E0EF',
    secondary: '#CAF0F8',
    highlight: '#00B4D8',
    button: '#0077B6',
    link: '#03045E',
    border: '#ADE8F4',
    hover: '#0096C7'
  },
  desertSand: {
    name: 'Desert Sand',
    primary: '#C19A6B',
    background: '#F4E3CF',
    text: '#5E503F',
    description: 'Warm and earthy',
    category: 'light',
    accent: '#D4B483',
    secondary: '#EAD7C7',
    highlight: '#A37B45',
    button: '#8A6642',
    link: '#6F4E37',
    border: '#DEB887',
    hover: '#B38B6D'
  },

  nordicFjord: {
    name: 'Nordic Fjord',
    primary: '#3A7CA5',
    background: '#D9DCD6',
    text: '#16425B',
    description: 'Scandinavian simplicity',
    category: 'light',
    accent: '#81A4CD',
    secondary: '#EAE6E5',
    highlight: '#2F6690',
    button: '#0F4C5C',
    link: '#5B88A5',
    border: '#C4C9C7',
    hover: '#6E99B4'
  },
  cosmicLatte: {
    name: 'Cosmic Latte',
    primary: '#FFF8E7',
    background: '#23395B',
    text: '#E8E8E8',
    description: 'Creamy space colors',
    category: 'light',
    accent: '#FFFDD0',
    secondary: '#406E8E',
    highlight: '#F0EAD6',
    button: '#8EA8C3',
    link: '#CBB997',
    border: '#4A6FA5',
    hover: '#DFD7BF'
  },
  Yellowjacket: {
    name: 'Yellowjacket',
    primary: '#ffd700',
    background: '#1a1a1a',
    text: '#ffffff',
    description: 'Bold and energetic',
    category: 'dark',
    accent: '#ffcc00',
    secondary: '#2a2a2a',
    highlight: '#ffe666',
    button: '#ffd900',
    link: '#ffdb1a',
    border: '#4a4a4a',
    hover: '#ffed99'
  },
  cyberpunk: {
    name: 'Cyberpunk',
    primary: '#FFE600',
    background: '#372963',
    text: '#00FF9C',
    description: 'High-tech low-life',
    category: 'dark',
    accent: '#FF00FF',
    secondary: '#261B41',
    highlight: '#00FFFF',
    button: '#FF3366',
    link: '#FF9900',
    border: '#4B3B8A',
    hover: '#FF66CC'
  },
  nightcity: {
    name: 'Night City',
    primary: '#00FFFF',
    background: '#120458',
    text: '#FF00FF',
    description: 'Cyberpunk-inspired neon nights',
    category: 'dark',
    accent: '#FF00CC',
    secondary: '#1A0A7A',
    highlight: '#33CCFF',
    button: '#FF3399',
    link: '#CC00FF',
    border: '#2A1499',
    hover: '#00CCCC'
  },
  dracula: {
    name: 'Dracula',
    primary: '#BD93F9',
    background: '#282A36',
    text: '#F8F8F2',
    description: 'A dark theme for the night owls',
    category: 'dark',
    accent: '#FF79C6',
    secondary: '#44475A',
    highlight: '#50FA7B',
    button: '#BD93F9',
    link: '#FFB86C',
    border: '#6272A4',
    hover: '#FF5555'
  },




  // AMOLED Themes
  monochrome: {
    name: 'Monochrome',
    primary: '#FFFFFF',
    background: '#000000',
    text: '#FFFFFF',
    description: 'Simple black and white',
    category: 'amoled',
    accent: '#CCCCCC',
    secondary: '#1A1A1A',
    highlight: '#F2F2F2',
    button: '#404040',
    link: '#BFBFBF',
    border: '#333333',
    hover: '#666666'
  },

 
  


  // Light Themes
  minimalist: {
    name: 'Minimalist',
    primary: '#2D2D2D',
    background: '#FFFFFF',
    text: '#000000',
    description: 'Clean and simple',
    category: 'light',
    accent: '#4A4A4A',
    secondary: '#F0F0F0',
    highlight: '#1A1A1A',
    button: '#333333',
    link: '#666666',
    border: '#CCCCCC',
    hover: '#888888'
  },
  solarizedLight: {
    name: 'Solarized Light',
    primary: '#073642',
    background: '#FDF6E3',
    text: '#657B83',
    description: 'Easy on the eyes',
    category: 'light',
    accent: '#268BD2',
    secondary: '#EEE8D5',
    highlight: '#2AA198',
    button: '#859900',
    link: '#CB4B16',
    border: '#93A1A1',
    hover: '#6C71C4'
  },
  arcticFrost: {
    name: 'Arctic Frost',
    primary: '#81A4CD',
    background: '#F0F3F5',
    text: '#2E4057',
    description: 'Cool and refreshing',
    category: 'light',
    accent: '#4A6FA5',
    secondary: '#DDE5ED',
    highlight: '#63C5DA',
    button: '#3D5A80',
    link: '#98C1D9',
    border: '#B8C7D9',
    hover: '#5B84B1'
  },
  

  // Pastel Themes
  subtleVaporwave: {
    name: 'Vaporwave',
    primary: '#FF6B9E',
    background: '#F2E4F9',
    text: '#5B37B7',
    description: 'Soft aesthetic vibes',
    category: 'pastel',
    accent: '#85D2D0',
    secondary: '#FDE2FF',
    highlight: '#C5A3FF',
    button: '#FF9DE6',
    link: '#A2F0E0',
    border: '#E0B0FF',
    hover: '#FFB6C1'
  },
  cherryBlossom: {
    name: 'Cherry Blossom',
    primary: '#ff607f',
    background: '#FFF0F5',
    text: '#DE3163',
    description: 'Delicate and dreamy',
    category: 'pastel',
    accent: '#FFC0CB',
    secondary: '#FFDAB9',
    highlight: '#FF69B4',
    button: '#DB7093',
    link: '#FF9999',
    border: '#FFE4E1',
    hover: '#FFA07A'
  },
  mintChocolate: {
    name: 'Mint Chocolate',
    primary: '#4ECCA3',
    background: '#2D2D2D',
    text: '#EEEEEE',
    description: 'Cool and refreshing',
    category: 'pastel',
    accent: '#45B7A0',
    secondary: '#393E46',
    highlight: '#66CDAA',
    button: '#3C8F7C',
    link: '#98FB98',
    border: '#40514E',
    hover: '#5CDB95'
  },
  lavenderFields: {
    name: 'Lavender Fields',
    primary: '#9F77CF',
    background: '#F0E6FF',
    text: '#4A0E4E',
    description: 'Calming purple hues',
    category: 'pastel',
    accent: '#B19CD9',
    secondary: '#E6D7FF',
    highlight: '#C3B1E1',
    button: '#8E6DAE',
    link: '#A39BD2',
    border: '#D8BFD8',
    hover: '#DDA0DD'
  },

  // Material Themes
  materialIndigo: {
    name: 'Material Indigo',
    primary: '#3F51B5',
    background: '#E8EAF6',
    text: '#212121',
    description: 'Classic Material Design',
    category: 'material',
    accent: '#536DFE',
    secondary: '#C5CAE9',
    highlight: '#7986CB',
    button: '#303F9F',
    link: '#5C6BC0',
    border: '#9FA8DA',
    hover: '#3D5AFE'
  },
  materialTeal: {
    name: 'Material Teal',
    primary: '#009688',
    background: '#E0F2F1',
    text: '#212121',
    description: 'Refreshing teal palette',
    category: 'material',
    accent: '#1DE9B6',
    secondary: '#B2DFDB',
    highlight: '#4DB6AC',
    button: '#00796B',
    link: '#26A69A',
    border: '#80CBC4',
    hover: '#00BFA5'
  },
  materialRed: {
    name: 'Material Red',
    primary: '#F44336',
    background: '#FFEBEE',
    text: '#212121',
    description: 'Bold and energetic',
    category: 'material',
    accent: '#FF5252',
    secondary: '#FFCDD2',
    highlight: '#EF5350',
    button: '#D32F2F',
    link: '#E57373',
    border: '#FFAB91',
    hover: '#FF1744'
  },
  materialPurple: {
    name: 'Material Purple',
    primary: '#9C27B0',
    background: '#F3E5F5',
    text: '#212121',
    description: 'Rich and regal',
    category: 'material',
    accent: '#E040FB',
    secondary: '#E1BEE7',
    highlight: '#AB47BC',
    button: '#7B1FA2',
    link: '#BA68C8',
    border: '#CE93D8',
    hover: '#D500F9'
  },
  materialGreen: {
    name: 'Material Green',
    primary: '#4CAF50',
    background: '#E8F5E9',
    text: '#212121',
    description: 'Natural and balanced',
    category: 'material',
    accent: '#69F0AE',
    secondary: '#C8E6C9',
    highlight: '#66BB6A',
    button: '#388E3C',
    link: '#81C784',
    border: '#A5D6A7',
    hover: '#00E676'
  },
  materialOrange: {
    name: 'Material Orange',
    primary: '#FF9800',
    background: '#FFF3E0',
    text: '#212121',
    description: 'Warm and inviting',
    category: 'material',
    accent: '#FFAB40',
    secondary: '#FFE0B2',
    highlight: '#FFA726',
    button: '#F57C00',
    link: '#FFB74D',
    border: '#FFCC80',
    hover: '#FF9100'
  },
  materialBlueGrey: {
    name: 'Material Blue Grey',
    primary: '#607D8B',
    background: '#ECEFF1',
    text: '#212121',
    description: 'Subtle and sophisticated',
    category: 'material',
    accent: '#78909C',
    secondary: '#CFD8DC',
    highlight: '#78909C',
    button: '#455A64',
    link: '#90A4AE',
    border: '#B0BEC5',
    hover: '#546E7A'
  },
};
